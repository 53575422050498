import React from 'react';
import PropTypes from 'prop-types';

function CircleTopBox({ img, iconId, title, id, index, contentList = [], showLine = false }) {
  return (
    <div className="top-box" key={id}>
      <div className="top-img-wrapper">
        {iconId ? <span className={`iconfont white font-50 ${iconId}`} /> : <img src={img} alt="img" className="top-img" />}
        {/* <img src={img} alt="img" className="top-img" /> */}
      </div>
      <div className="content-list-wrapper">
        <p className="title">{title}</p>
        {contentList.map((content) => (
          <div className="content-wrapper" key={content.id}>
            {content.subTitle && <p className="sub-title">{content.subTitle}</p>}
            {(content.subContentList || []).map((item) => (
              <div
                className={`sub-content ${item.smBlock ? 'sm-block' : ''} column-${(content.subContentList || []).length}`}
                key={item.id}
              >
                {item.subContent}
              </div>
            ))}
          </div>
        ))}
      </div>
      {showLine && index < 2 && <div className="horizontal-line" />}
    </div>
  );
}

CircleTopBox.propTypes = {
  img: PropTypes.string,
  iconId: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  showLine: PropTypes.bool,
  contentList: PropTypes.arrayOf(
    PropTypes.shape({
      subTitle: PropTypes.string,
      id: PropTypes.string,
      subContentList: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          subContent: PropTypes.string,
        })
      ),
    })
  ),
};

export default CircleTopBox;