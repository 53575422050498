import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import CircleTopBox from './CircleTopBox';

const TOP_BOX_DATA = [
  {
    iconId: 'iconmarketing-system-2',
    id: '1',
    title: '权益',
    contentList: [
      {
        id: '1-1',
        subTitle: '积分管理',
        subContentList: [
          { id: '1-1-1', subContent: '定级积分' },
          { id: '1-1-2', subContent: '权益积分' },
          { id: '1-1-3', subContent: '积分中心' },
        ],
      },
      {
        id: '1-2',
        subTitle: '权益管理',
        subContentList: [
          { id: '1-2-1', subContent: '优惠券发放' },
          { id: '1-2-2', subContent: '优惠券核销' },
          { id: '1-2-3', subContent: '权益码管理' },
        ],
      },
    ],
  },
  {
    iconId: 'iconmarketing-system-1',
    id: '2',
    title: '商品',
    contentList: [
      {
        id: '2-1',
        subTitle: '商品交易渠道',
        subContentList: [
          { id: '2-1-1', subContent: '平台自建商城' },
          { id: '2-1-2', subContent: '自媒体' },
          { id: '2-1-3', subContent: '小程序' },
          { id: '2-1-4', subContent: '门店' },
        ],
      },
      {
        id: '2-2',
        subTitle: '商品交易触点',
        subContentList: [
          { id: '2-2-1', subContent: 'POS机' },
          { id: '2-2-2', subContent: '一物一码' },
          { id: '2-2-3', subContent: '导购' },
          { id: '2-2-4', subContent: '卡券' },
        ],
      },
      // {
      //   id: '2-3',
      //   subTitle: '后台管理系统',
      //   subContentList: [
      //     { id: '2-3-1', subContent: 'OMS系统' },
      //     { id: '2-3-2', subContent: 'ERP系统' },
      //     { id: '2-3-3', subContent: 'CRM系统' },
      //   ],
      // },
    ],
  },
  {
    iconId: 'iconmarketing-system-3',
    id: '3',
    title: '会员',
    contentList: [
      {
        id: '3-1',
        subTitle: '会员管理',
        subContentList: [
          { id: '3-1-1', subContent: '会员等级' },
          { id: '3-1-2', subContent: '会员积分' },
          { id: '3-1-3', subContent: '会员分层' },
        ],
      },
      {
        id: '3-2',
        subTitle: '会员营销',
        subContentList: [
          { id: '3-2-1', subContent: '会员管理中台', smBlock: true },
          { id: '3-2-2', subContent: '会员自动化营销', smBlock: true },
        ],
      },
    ],
  },
];

const MIDDLE_BOX_DATA = [
  {
    id: '1',
    title: '触达能力协同',
    contentList: [
      { id: '1-1', subTitle: '微信', smOrder: 1 },
      { id: '1-2', subTitle: '短信', smOrder: 2 },
      { id: '1-3', subTitle: 'App push', smOrder: 4 },
      { id: '1-4', subTitle: '小程序消息', smOrder: 5 },
      { id: '1-5', subTitle: 'EDM', smOrder: 3 },
    ],
  },
  {
    id: '2',
    title: '部门营销协同',
    contentList: [
      { id: '2-1', subTitle: '营销部门' },
      { id: '2-2', subTitle: '门店' },
      { id: '2-3', subTitle: 'CRM部门' },
      { id: '2-4', subTitle: 'EC电商部门' },
    ],
  },
];

const BOTTOM_BOX_DATA = [
  { id: '1', title: 'Data Hub\n客户管理平台' },
  { id: '2', title: 'DM Hub 营销自动化平台' },
  { id: '3', title: 'Ad Hub\n智能广告平台' },
];

const Arrow = () => {
  return (
    <div className="arrow-wrapper">
      <div className="point" />
      <div className="line" />
    </div>
  );
};

const ArrowBox = ({ title, id, showHoriLine, contentList = [] }) => {
  return (
    <div className="arrow-box" key={id}>
      <Arrow />
      <p className="title">{title}</p>
      <div className="content-wrapper">
        {contentList.map((item) => (
          <div key={item.id} className={`content ${item.smOrder ? `sm-order-${item.smOrder}` : ''} column-${contentList.length}`}>
            {item.subTitle}
          </div>
        ))}
      </div>
      {showHoriLine && <div className="horizontal-line" />}
    </div>
  );
};

ArrowBox.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  showHoriLine: PropTypes.bool,
  contentList: PropTypes.arrayOf(
    PropTypes.shape({
      subTitle: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default function BrandRetailMarketingSystem() {
  return (
    <section id="brand-marketing" className="brand-marketing">
      <Container>
        <h3>品牌零售行业数字化营销体系</h3>
        <div className="top-box-wrapper">
          {TOP_BOX_DATA.map((data, index) => {
            return <CircleTopBox key={data.id} index={index} {...data} showLine />;
          })}
        </div>
        <div className="middle-box-wrapper">
          {MIDDLE_BOX_DATA.map((data, index) => {
            return (
              <ArrowBox key={data.id} showHoriLine={index < MIDDLE_BOX_DATA.length - 1} {...data} />
            );
          })}
        </div>
        <div className="bottom-box-wrapper">
          {BOTTOM_BOX_DATA.map((data, index) => {
            return (
              <ArrowBox key={data.id} showHoriLine={index < BOTTOM_BOX_DATA.length - 1} {...data} />
            );
          })}
        </div>
      </Container>
    </section>
  );
}
