import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import BrandRetailMarketingSystem from '../components/BrandRetailMarketingSystem';
import CustomerCase from '../components/CustomerCase';
import VerticalBox from '../components/VerticalBox';
import IconText from '../components/IconText';
import Chiaus from '../images/chiaus.svg';

function CustomIconText({ title, icon, content }) {
  return (
    <Col xl={6} sm={12} className="icon-text-col">
      <IconText title={title} content={content} icon={icon} iconPosition="top" />
    </Col>
  );
}

CustomIconText.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.string,
};

export default () => (
  <Layout current="solutions" subCurrent="brandretail">
    <CommonPageBanner
      title="品牌零售行业解决方案"
      description="通过全渠道触达能力，帮助品牌零售企业建立“商品+权益+会员”的组合式打法，满足零售企业对营销中台、数据中台和内容中台的需求，实现“以客户为中心”的零售行业营销数字化转型。"
      imgName="brand-retail"
    />
    <TitledVerticalBoxes title="零售行业面临的三大问题" shadowed sectionId="brandretail">
      <Col className="icon-text-col" span={8}>
        <VerticalBox iconIndex={7} description="客户究竟是什么样的人" />
      </Col>
      <Col className="icon-text-col" span={8}>
        <VerticalBox iconIndex={10} description="商品应该推荐给哪位客户" />
      </Col>
      <Col className="icon-text-col" span={8}>
        <VerticalBox iconIndex={38} description="应该如何与客户进行沟通" />
      </Col>
    </TitledVerticalBoxes>
    <BrandRetailMarketingSystem />
    <TitledVerticalBoxes title="Convertlab可实现丰富的零售应用场景" blue>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={34}
          title="一物一码"
          description="实现零售品牌与终端消费者，建立从线下跨越线上的数据追溯体系"
        />
      </Col>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={14}
          title="RFM模型分析"
          description="基于CDP平台搭建RFM分析模型，洞察会员的价值、活跃度和流失情况"
        />
      </Col>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={33}
          title="广告投放优化"
          description="智能化、数字化、自动化的智能广告平台AD Hub"
        />
      </Col>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={39}
          title="微信运营"
          description="通过48h运营、模板消息推送和自动化营销，实现拉新引流和客户增长"
        />
      </Col>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={40}
          title="营销流程闭环"
          description="营销全流程中形成的数据精准回流，实现零售全流程营销闭环"
        />
      </Col>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={17}
          title="会员管理"
          description="搭建完整的会员标签、会员画像、会员权益和会员积分的管理体系"
        />
      </Col>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={10}
          title="精准内容推荐"
          description="智能化AI推荐算法，为品牌零售提供精准的商品推荐和人群匹配"
        />
      </Col>
      <Col lg={6} md={12} xs={12} className="same">
        <VerticalBox
          iconIndex={2}
          title="用户画像洞察"
          description="可自动化生成客户的360°画像，实现对客户群体的精准洞察"
        />
      </Col>
    </TitledVerticalBoxes>
    <CustomerCase
      title="品牌零售客户案例"
      icon={Chiaus}
      description="拥有十余年历史的国内领先母婴品牌，集研发、设计、生产、销售、售后服务为一体的专业制造婴幼童系列健康护理用品的大型企业。公司重视研发创新，拥有数十项发明专利，同时不断更新生产设备，确保产品的精益求精。凭借着强劲的市场口碑，该母婴品牌已连续六年成为头部电商销量第一的中国纸尿裤品牌。"
      challenges={[
        {
          id: 'icon-1',
          iconIndex: 35,
          className: 'thin-square',
          description: '天猫、淘宝、京东、苏宁易购、唯品会等多个电商渠道的数据孤立不互通，难以助力销售。',
        },
        {
          id: 'icon-2',
          iconIndex: 7,
          className: 'thin-square',
          description: '原有会员中心开发较早，功能单一，页面粗糙，整个互动体验较差，且积分规则不严谨。',
        },
        {
          id: 'icon-3',
          iconIndex: 31,
          className: 'thin-square',
          description: '会员与非会员无差别运营，触达内容不具个性化，关键节点触达不具时效性。',
        },
        {
          id: 'icon-4',
          iconIndex: 18,
          className: 'thin-square',
          description: '百万级别的历史会员数据绝大部分处于沉睡状态，没有很好刺激消费和品牌传播。',
        },
      ]}
      solutions={[
        '建立CDP平台，聚合微信、会员、交易等各渠道数据形成全域消费者画像。基于数据洞察之上进行个性化精准触达，提升用户体验，刺激消费转化。',
        '重构会员中心、积分商城及积分规则，千人千面展示宝宝成长阶段及互动任务，每个人每一天访问都是眼前一新。',
        '邀请有礼、0元试用、会员日、破蛋日、成长礼等等有趣多样的会员活动促进品牌推广及会员复购。',
      ]}
      effects={[
        {
          id: 'icon-1',
          iconId: 'iconeffect-12',
          content: '上线2周，累计新增粉丝占总粉丝的28%，新会员注册率高达84%。',
        },
        {
          id: 'icon-2',
          iconId: 'iconeffect-13',
          content: '新注册会员购买金额占到整个会员购买金额的22%，环比销售总额增长49%。',
        },
      ]}
    />
  </Layout>
);
